<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-1">
        {{ $t(`Fulfillment for Order ${orderType}-${id}`) }}
      </h3>
    </portal>
    <skeleton v-if="isLoading" />
    <dispatch-order-form v-if="orderItem" />
  </div>
</template>

<script>
import Skeleton from '@/views/main/warehouse/view/pick/rental-sales/components/Skeleton.vue'
import DispatchOrderForm from '@/views/main/warehouse/view/dispatch/components/DispatchOrderForm.vue'
import store from '@/store'
import dispatchModule from '@/store/main/warehouse/dispatch'
import { onUnmounted } from '@vue/composition-api'
import config from './dispatchConfig'

export default {
  components: {
    DispatchOrderForm,
    Skeleton,
  },
  data() {
    return {
      id: this.$route.params.id,
      orderType: this.$route.params.orderType,
    }
  },
  computed: {
    isLoading() {
      return this.$store.state[this.MODULE_NAME].isLoading
    },
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
  },
  created() {
    this.getOrderItem(this)
  },
  setup() {
    const { MODULE_NAME, getOrderItem } = config()

    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, dispatchModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME,
      getOrderItem,
    }
  },

}
</script>
