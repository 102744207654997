<template>
  <div
    class="bg-white mb-1"
    style="border-radius: 5px; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);"
  >
    <b-overlay
      variant="white"
      :show="isLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-row class="mt-1 pb-1 ml-0">
        <b-col cols="6">
          <span class="font-weight-bolder font-medium-5 d-block pt-1">
            {{ $t('Authorized Dispatch Contacts') }}
          </span>
          <div>
            <b-form-group
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio
                v-for="contact in shippingContactList"
                :key="contact.id"
                v-model="dispatch_contact_id"
                class="custom-radio-group-input"
                :aria-describedby="ariaDescribedby"
                :name="`${contact.id}`"
                :value="contact.id"
              >
                {{ contact.fullName }}
                <b-link
                  target="_blank"
                  :to="{ name: 'home-customers-contacts-update', params: { contact_id: contact.id,id: orderItem.customer_id } }"
                >  <feather-icon
                  icon="LEyeIcon"
                  size="18"
                  class="mr-1 cursor-pointer"
                /> </b-link>
              </b-form-radio>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="d-flex align-items-center justify-content-between pt-1">
            <span class="font-weight-bolder font-medium-5 d-block ">
              {{ $t('ID (Front)') }}
            </span>
            <feather-icon
              icon="LLoadIcon"
              size="18"
              class="mr-1 cursor-pointer"
              @click="shippingContact"
            />
          </div>
          <img
            v-if="getSelectedImage"
            width="547px"
            height="343px"
            :src="getSelectedImage.url"
            alt="aw"
          >
          <div v-else>
            <span class="d-flex align-items-center justify-content-center mt-2">
              {{ $t('No image available') }}
            </span>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCol, BFormGroup, BFormRadio, BLink, BOverlay, BRow,
} from 'bootstrap-vue'
import config from '@/views/main/warehouse/view/dispatch/dispatchConfig'

export default {
  name: 'AuthorizedDispatchContacts',
  components: {
    BLink,
    BOverlay,
    BFormRadio,
    BFormGroup,
    BRow,
    BCol,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
    dispatch_contact_id: {
      get() {
        return this.$store.state[this.MODULE_NAME].dispatch_contact_id
      },
      set(value) {
        this.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_CONTACT_ID`, value)
      },
    },
    shippingContactList() {
      return this.$store.state.listModule.shippingContact
    },
    getSelectedImage() {
      const selectedContact = this.shippingContactList.find(contact => contact.id === this.dispatch_contact_id)
      return selectedContact && selectedContact?.attachments.find(contact => contact.attachment_category_id === 6)
    },
  },
  mounted() {
    this.shippingContact()
  },
  methods: {
    shippingContact() {
      const parentValue = this.orderItem.customer_id
      if (!parentValue) return

      this.isLoading = true
      this.$store.dispatch('listModule/shippingContact', {
        parentValue,
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
  setup() {
    const { preferredContact, MODULE_NAME } = config()
    return {
      preferredContact,
      MODULE_NAME,
    }
  },
}
</script>

<style scoped lang="scss">
.custom-radio-group-input {
  margin-top: 25px !important;
}
</style>
